<template>
  <div class="xmx-auto xmax-w-[1200px]">
    <v-progress-linear
      indeterminate
      :height="10"
      class="my-1"
      v-if="fetching"
      color="primary"
    ></v-progress-linear>
    <v-card :key="hardreload" flat class="xw-full xshadow-lg xmin-h-screen">
      <v-alert
        dense
        type="warning"
        outlined
        v-if="!$can.and('update_team_preferences')"
      >
        You don't have enough permission to update these preferences.
      </v-alert>
      <!-- Select channels for clocking/notification -->
      <EachSetting
        title="Set Time Tracking Channels"
        subtitle="You can set dedicated channels for team members' clocking activities."
      >
        <template v-slot:left>
          <div :class="[infoBlock]">
            Click on the Slack icon (<v-icon size="15" color="primary">mdi-slack</v-icon>) if you have created a new channel for this.
            <br />
            Note: The channel should be public and not archived.
          </div>
        </template>
        <template v-slot:right>
          <TeamChannelsSelect
            dense
            v-model="workspace_channels"
            :disabled="!$can.and('update_team_preferences')"
            :team="team_id"
            class="xmax-w-[300px]"
          ></TeamChannelsSelect>
        </template>
      </EachSetting>
      <!-- <v-divider /> -->
      <!-- Require Task/Project When Clocking -->
      <EachSetting
        title="Require Task/Project When Clocking"
        subtitle="Require members to set a task or project when clocking <strong class='xtext-red-500'>in</strong>."
      >
        <template v-slot:left>
          <strong>Off</strong>
          <div :class="[infoBlock]">
            Team members won't be asked to specify their project or task in their Slackbot.
          </div>
          <strong>Optional</strong>
          <div :class="[infoBlock]">
            Default. Team members can clock in without specifying what task or project they are working on.
          </div>
          <strong>Required</strong>
          <div :class="[infoBlock]">
            Team members must set a task or project when clocking in. The full command should be
            <code class="!xtext-error">/in [project/task]</code>.
          </div>
        </template>
        <template v-slot:right>
          <v-select
            hide-details="auto"
            dense
            v-model="required_clocking_purpose"
            outlined
            class="xmax-w-[300px] xmx-auto"
            :disabled="!$can.and('update_team_preferences')"
            :items="require_purpose_list"
          ></v-select>
        </template>
      </EachSetting>
      <!-- <v-divider /> -->
      <EachSetting
        title="Allow Creating New Project/Task During Clock-in"
        subtitle="Prevent members from creating a new project or task during clock-in (<strong class='!xtext-error'>/in</strong> and <strong class='xtext-error'>/switch</strong>)."
      >
        <template v-slot:left>
          <strong>Not Allowed</strong>
          <div :class="[infoBlock]">
            Team members cannot create their own projects or tasks. Only predefined or assigned tasks/projects can be set during clock-in.
          </div>
          <strong>Allowed</strong>
          <div :class="[infoBlock]">
            Default. Team members can create their own projects or tasks.
          </div>
        </template>
        <template v-slot:right>
          <div class="xw-full xflex xflex-col">
            <div
              class="xflex xflex-row xjustify-center xmax-w-[300px] xgap-x-4 xitems-center xp-2"
            >
              <span class="xfont-[400] xtext-[15px]">Not Allowed</span>
              <v-switch
                :disabled="!$can.and('update_team_preferences')"
                :true-value="1"
                :false-value="0"
                color="#19CB40"
                v-model="allow_user_create_project_tasks"
                inset
              >
              </v-switch>
              <span class="xfont-[400] xtext-[15px]">Allowed</span>
            </div>
          </div>
        </template>
      </EachSetting>
      <!-- <v-divider /> -->
      <!-- Check if User is Idle -->
      <EachSetting
        title="Check if User is Idle"
        subtitle="The bot will clock out users who do not respond within the specified time, preventing team members from remaining idle."
      >
        <template v-slot:left>
          <strong>Off</strong>
          <div :class="[infoBlock]">
            If set to <b>Off</b>, the team member will not receive a notification for being idle. They will also not be clocked out until they reach the accumulated work time set in <b>Limit Maximum Work Time per Shift</b>, if that is set to <b>On</b>.
          </div>
          <strong>Individual Accumulated Time</strong>
          <div :class="[infoBlock]">
            Team members will receive a notification when their accumulated work time reaches the limit set in the <b>Idle Notification Interval</b>. They must respond by clicking a button on the notification message within 10 minutes; otherwise, they will be automatically clocked out.
          </div>
          <!-- <strong> Interval-Based Notification </strong>
          <div :class="[infoBlock]">
            All team members will receive a notification at regular intervals based on the number of hours set in the notification interval (1-23 hours). If a team member does not respond within 10 minutes by clicking a button on the notification message, they will be automatically clocked out. This helps ensure users remain active and engaged during their shift.
          </div>
          <strong> Random Notification </strong>
          <div :class="[infoBlock]">
            Two team members will receive notifications when the current interval reaches the time set in the <b>Idle Notification Interval</b>. They are required to respond by clicking a button on the notification message within 10 minutes. Otherwise, the team member will be automatically clocked out.
          </div> -->
        </template>
        <template v-slot:right>
          <v-select
            hide-details="auto"
            dense
            class="xmax-w-[300px] xmx-auto"
            :disabled="!$can.and('update_team_preferences')"
            v-model="notification_type"
            outlined
            :items="idle_user_list"
          ></v-select>
        </template>
      </EachSetting>
      <!-- <v-divider /> -->
      <!-- Idle Notification Interval -->
      <EachSetting
        title="Idle Notification Interval"
        subtitle="The bot will send a notification to team members after the specified time."
      >
        <template v-slot:left>
          <strong>
            Check if User is Idle for <i>Individual Accumulated Time</i>
          </strong>
          <div :class="[infoBlock]">
            A team member will receive a notification when their accumulated work time reaches the specified duration for the selected interval.
          </div>

          <!-- <strong>
            Check if User is Idle for <i>Interval-Based Notification</i>
          </strong>
          <div :class="[infoBlock]">
            All team members will receive a notification at regular intervals based on the number of hours set in the notification interval (1-23 hours). If a team member does not respond within 10 minutes by clicking a button on the notification message, they will be automatically logged out. This helps ensure users remain active and engaged during their shift.
          </div>
          <strong> Check if User is Idle for <i>Random Notification</i> </strong>
          <div :class="[infoBlock]">
            Two team members are randomly selected to receive a notification at each specified interval, regardless of their individual accumulated total work time.
          </div> -->
        </template>
        <template v-slot:allright>
          <!-- <div
            class="xcol-span-1 xflex xflex-row xjustify-center xgap-x-4 xitems-center xp-2"
          >
            <span class="xfont-semibold">1</span>
            <v-slider
              height="20"
              track-fill-color="accent"
              min="1"
              class="xw-full xmax-w-[300px] xmx-auto"
              step="1"
              max="23"
              :disabled="
                notification_type === 'off' ||
                !$can.and('update_team_preferences')
              "
              v-model="notification_interval"
              hide-details="auto"
              ticks="always"
              thumb-color="primary"
              thumb-label="always"
            ></v-slider>
            <span class="xfont-semibold">23</span>
          </div> -->
          <v-select
            outlined
            dense
            item-text="text"
            item-value="value"
            hide-details="auto"
            v-model="notification_interval"
            :disabled="
              notification_type === 'off' ||
              !$can.and('update_team_preferences')
            "
            :items="notification_interval_array"
            class="xmax-w-[300px] xmx-auto"
          >
          </v-select>
        </template>
      </EachSetting>
      <!-- <v-divider /> -->
      <!-- Limit Maximum Work Time per Shift -->
      <EachSetting
        title="Limit Maximum Work Time per Shift"
        subtitle="You can set a limit on the total work time for each shift."
      >
        <template v-slot:left>
          <div :class="[infoBlock]">
            You can specify the maximum allowed work time for a team member each shift. This helps prevent idle members from forgetting to clock out.
          </div>
        </template>
        <template v-slot:allright>
          <!-- <div
            class="xflex xflex-row xmax-w-[300px] xitems-center xjustify-center xgap-x-4 xp-2"
          >
            <span class="xfont-semibold">{{ min_limit_per_shift }}</span>
            <v-slider
              height="20"
              track-fill-color="accent"
              :min="min_limit_per_shift"
              class="xw-full"
              step="1"
              max="24"
              :disabled="!$can.and('update_team_preferences')"
              v-model="limit_per_shift"
              hide-details="auto"
              ticks="always"
              thumb-color="primary"
              thumb-label="always"
            ></v-slider>
            <span class="xfont-semibold">24</span>
          </div> -->
          <v-select
            outlined
            dense
            item-text="text"
            item-value="value"
            hide-details="auto"
            v-model="limit_per_shift"
            :disabled="!$can.and('update_team_preferences')"
            :items="limit_per_shift_array"
            class="xmax-w-[300px] xmx-auto"
          >
          </v-select>
        </template>
      </EachSetting>
      <!-- <v-divider /> -->
      <!-- Limit Maximum Break Time per Shift -->
      <EachSetting
        title="Limit Maximum Break Time per Shift"
        subtitle="You can limit the total break time allowed for a team member each shift."
      >
        <template v-slot:left>
          <div :class="[infoBlock]">
            You can specify the maximum allowed break time for a team member each shift. This helps ensure that idle members remember to clock out while on break.
          </div>
        </template>
        <template v-slot:allright>
          <!-- <div
            class="xcol-span-1 xflex xflex-row xjustify-center xgap-x-4 xitems-center xp-2"
          >
            <span class="xfont-semibold">1</span>
            <v-slider
              height="20"
              track-fill-color="accent"
              min="1"
              class="xw-full xmax-w-[300px]xmx-auto"
              max="24"
              :disabled="!$can.and('update_team_preferences')"
              v-model="limit_per_break"
              hide-details="auto"
              ticks="always"
              thumb-color="primary"
              thumb-label="always"
            ></v-slider>
            <span class="xfont-semibold">24</span>
          </div> -->
          <v-select
            outlined
            dense
            item-text="text"
            item-value="value"
            hide-details="auto"
            v-model="limit_per_break"
            :disabled="!$can.and('update_team_preferences')"
            :items="limit_per_break_array"
            class="xmax-w-[300px] xmx-auto"
          >
          </v-select>
        </template>
      </EachSetting>
      <!-- <v-divider /> -->
      <!-- Notify User to Clock In -->
      <EachSetting
        :title="`Notify User to Clock In (${timezone})`"
        subtitle="Mesasix Time Tracker Bot will notify each user if they haven’t clocked in for the day."
      >
        <template v-slot:left>
          <div :class="[infoBlock]">
            You can specify the start time of your shift, and the bot will remind the user to clock in.
          </div>
        </template>
        <template v-slot:right>
          <v-autocomplete
            hide-details="auto"
            dense
            v-model="start_shift"
            outlined
            class="xmax-w-[300px] xmx-auto"
            :disabled="!$can.and('update_team_preferences')"
            item-value="value"
            :items="time_list"
          ></v-autocomplete>
        </template>
      </EachSetting>
      <!-- <v-divider /> -->
      <!-- Force Clock Out Users -->
      <EachSetting
        :title="`Force Clock Out Users (${timezone})`"
        subtitle="Mesasix Time Tracker Bot will automatically clock out users at the specified time."
      >
        <template v-slot:left>
          <div :class="[infoBlock]">
            You can specify the end time of your shift, and the bot will automatically clock out all users who are currently clocked in, including those on break.
          </div>
        </template>
        <template v-slot:right>
          <v-autocomplete
            hide-details="auto"
            dense
            :disabled="!$can.and('update_team_preferences')"
            v-model="end_shift"
            outlined
            class="xmax-w-[300px] xmx-auto"
            item-value="value"
            :items="time_list"
          ></v-autocomplete>
        </template>
      </EachSetting>
      <!-- <v-divider /> -->
      <!-- Set Default Time Zone -->
      <EachSetting
        title="Set Default Time Zone"
        subtitle="You can set a preferred time zone for team members who haven't set their time zone in their Slack profile."
      >
        <template v-slot:left>
          <div :class="[infoBlock]">
            You can set the team’s time zone to your local time or any preferred time zone. This will be used for clocking activities if a member hasn’t set their own time zone.
          </div>
        </template>
        <template v-slot:right>
          <v-autocomplete
            hide-details="auto"
            dense
            class="xmax-w-[300px] xmx-auto"
            :disabled="!$can.and('update_team_preferences')"
            v-model="timezone"
            outlined
            :items="timezones"
            item-text="fullname"
            item-value="name"
          ></v-autocomplete>
        </template>
      </EachSetting>
      <!-- <v-divider /> -->
      <v-card-actions
        class="xflex xflex-row xgap-3 mt-2 xjustify-center xitems-center"
      >
        <v-btn
          class="px-5 text-none xfont-semibold"
          outlined
          dark
          v-if="$can.and('update_team_preferences')"
          :disabled="submiting"
          color="#353535"
          depressed
          @click="cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="$can.and('update_team_preferences')"
          class="px-5 text-none xfont-semibold"
          :loading="submiting"
          color="primary"
          depressed
          :disabled="fetching"
          @click="saveChanges"
        >
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import EachSetting from "../components/EachSetting.vue";
import SelectMultiple from "@/views/GlobalComponents/Forms/SelectMultiple.vue";
import TeamChannelsSelect from "@/views/GlobalComponents/Forms/TeamChannelsSelect.vue";
export default {
  props: {
    teamId: { type: String, default: null },
  },
  data() {
    return {
      infoBlock:
        "xborder-l-4 xmy-1 xborder-[#2e2e2e] xp-2 xtext-[#2e2e2e] xfont-[400] xtext-[14px] xmax-w-[800px]",
      hardreload: 0,
      require_purpose_list: [
        { text: "Off", value: "off" },
        { text: "Optional", value: "optional" },
        { text: "Required", value: "required" },
      ],
      idle_user_list: [
        { text: "Off", value: "off" },
        { text: "Individual Accumulated Time", value: "accumulated" },
        // { text: "Interval-Based Notification", value: "interval" },
        // { text: "Random Notification", value: "random" },
      ],
      time_list: [
        { value: "off", text: "Off" },
        { value: "08:00", text: "8:00 AM" },
        { value: "09:00", text: "9:00 AM" },
        { value: "10:00", text: "10:00 AM" },
        { value: "11:00", text: "11:00 AM" },
        { value: "12:00", text: "12:00 PM" },
        { value: "13:00", text: "1:00 PM" },
        { value: "14:00", text: "2:00 PM" },
        { value: "15:00", text: "3:00 PM" },
        { value: "16:00", text: "4:00 PM" },
        { value: "17:00", text: "5:00 PM" },
        { value: "18:00", text: "6:00 PM" },
        { value: "19:00", text: "7:00 PM" },
        { value: "20:00", text: "8:00 PM" },
        { value: "21:00", text: "9:00 PM" },
        { value: "22:00", text: "10:00 PM" },
        { value: "23:00", text: "11:00 PM" },
        { value: "00:00", text: "12:00 AM" },
        { value: "01:00", text: "1:00 AM" },
        { value: "02:00", text: "2:00 AM" },
        { value: "03:00", text: "3:00 AM" },
        { value: "04:00", text: "4:00 AM" },
        { value: "05:00", text: "5:00 AM" },
        { value: "06:00", text: "6:00 AM" },
        { value: "07:00", text: "7:00 AM" },
      ],
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("appconfigs", ["timezones"]),
    ...mapGetters("workspaceconfigs", [
      "configs",
      "submiting",
      "fetching",
      "getByKey",
    ]),
    team_id() {
      return this.teamId ? this.teamId : this.user.team_id;
    },
    min_limit_per_shift() {
      if (this.notification_type === "off") return 1;
      else return this.notification_interval + 1;
    },
    workspace_channels: {
      get() {
        return this.getByKey("workspace_channels");
      },
      set(val) {
        this.set_config({ key: "workspace_channels", value: val });
      },
    },
    limit_per_break: {
      get() {
        return this.getByKey("limit_per_break");
      },
      set(val) {
        this.set_config({ key: "limit_per_break", value: val });
      },
    },
    limit_per_shift: {
      get() {
        return this.getByKey("limit_per_shift");
      },
      set(val) {
        this.set_config({ key: "limit_per_shift", value: val });
      },
    },
    notification_interval: {
      get() {
        return this.getByKey("notification_interval");
      },
      set(val) {
        this.set_config({ key: "notification_interval", value: val });
      },
    },
    notification_type: {
      get() {
        return this.getByKey("notification_type");
      },
      set(val) {
        this.set_config({ key: "notification_type", value: val });
      },
    },
    required_clocking_purpose: {
      get() {
        return this.getByKey("required_clocking_purpose");
      },
      set(val) {
        this.set_config({ key: "required_clocking_purpose", value: val });
      },
    },
    start_shift: {
      get() {
        return this.getByKey("start_shift");
      },
      set(val) {
        this.set_config({ key: "start_shift", value: val });
      },
    },
    end_shift: {
      get() {
        return this.getByKey("end_shift");
      },
      set(val) {
        this.set_config({ key: "end_shift", value: val });
      },
    },
    timezone: {
      get() {
        return this.getByKey("timezone");
      },
      set(val) {
        this.set_config({ key: "timezone", value: val });
      },
    },
    allow_user_create_project_tasks: {
      get() {
        return this.getByKey("allow_user_create_project_tasks");
      },
      set(val) {
        this.set_config({ key: "allow_user_create_project_tasks", value: val });
      },
    },
    payload() {
      return {
        limit_per_break: this.limit_per_break,
        limit_per_shift: this.limit_per_shift,
        notification_interval: this.notification_interval,
        notification_type: this.notification_type,
        required_clocking_purpose: this.required_clocking_purpose,
        start_shift: this.start_shift,
        end_shift: this.end_shift,
        timezone: this.timezone,
        workspace_channels: this.workspace_channels,
        allow_user_create_project_tasks: this.allow_user_create_project_tasks,
      };
    },
    notification_interval_array() {
      return Array.from({ length: 23 }, (_, i) => i + 1).map((j) => {
        return {
          value: j,
          text: j < 2 ? `${j} hr` : `${j} hrs`,
        };
      });
    },
    limit_per_shift_array() {
      let start = this.min_limit_per_shift;
      let maxLength = 24 - (start - 1);
      return Array.from({ length: maxLength }, (_, i) => i + start).map((j) => {
        return {
          value: j,
          text: j < 2 ? `${j} hr` : `${j} hrs`,
        };
      });
    },
    limit_per_break_array() {
      return Array.from({ length: 24 }, (_, i) => i + 1).map((j) => {
        return {
          value: j,
          text: j < 2 ? `${j} hr` : `${j} hrs`,
        };
      });
    },
  },
  methods: {
    ...mapMutations(["set_step1"]),
    ...mapMutations("workspaceconfigs", ["set_config", "cancel_updates"]),
    ...mapActions("workspaceconfigs", [
      "fetchWorkspaceConfigs",
      "updateWorkspaceConfigs",
    ]),
    cancel() {
      this.cancel_updates();
      this.hardreload++;
    },
    saveChanges() {
      this.updateWorkspaceConfigs({
        id: this.team_id,
        payload: this.payload,
        cb: () => {
          this.appToast("Success! Preferences updated.", "success");
          this.set_step1(false);
        },
      });
    },
  },
  created() {
    this.fetchWorkspaceConfigs(this.team_id);
  },
  components: { EachSetting, SelectMultiple, TeamChannelsSelect },
};
</script>

<style lang="scss" scoped></style>
